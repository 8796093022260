// React & Gatsby
import React from "react"
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";

// Custom
import ArticlePage from "../components/Content/ArticlePage"
import PillButton from "../components/PillButton";
import Button from "react-bootstrap/Button";


const ContactPage = ((props) => {

    const { register, handleSubmit, watch, errors } = useForm();
    const onSubmit = (data, e) => {
        console.log('form data', [
            data, e, e.target.submit
        ]);
        e.target.submit();
    }

    return (
        <React.Fragment>
            <ArticlePage slug={'contact'}>
                <Container>
                    <Row fluid className={'justify-content-center mt-4 mb-4'}>
                        <Col md={1} xs={false} sm={false}/>
                        <Col id={'hs-column'} md={9} className={'pl-4 pr-4'}>
                            <form
                                id={'contact-form'}
                                name={'vectoricons-contact'}
                                data-netlify="true"
                                method={'POST'}
                                netlify-honeypot={'bot-field'}
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <input type="hidden" name="bot-field" />
                                <input type="hidden" name="form-name" value="vectoricons-contact" />
                                <Container>
                                    <Row>
                                        <Col>
                                            <label htmlFor={'email'}>
                                                Email
                                                <span className={'error'}>
                                                    {errors.email && errors.email.message}
                                                </span>
                                            </label>
                                            <input
                                                id="email"
                                                type={'email'}
                                                name="email"
                                                placeholder="Email Address"
                                                defaultValue=""
                                                autoComplete="email"
                                                ref={register({
                                                    required: "Required",
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message: "Please provide a valid email address"
                                                    }
                                                })}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <label htmlFor={'firstName'}>
                                                First Name
                                                <span className={'error'}>
                                                    {errors.firstName && errors.firstName.message}
                                                </span>
                                            </label>
                                            <input
                                                id="firstName"
                                                type={'text'}
                                                name="firstName"
                                                placeholder="First Name"
                                                defaultValue=""
                                                ref={register({
                                                    required: "Please provide your first name"
                                                })}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <label htmlFor={'lastName'}>Last Name</label>
                                            <input
                                                id="lastName"
                                                type={'text'}
                                                name="lastName"
                                                placeholder="Last Name"
                                                defaultValue=""
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <label htmlFor="message">
                                                Message
                                                <span className={'error'}>
                                                    {errors.message && errors.message.message}
                                                </span>
                                            </label>
                                            <textarea
                                                id="message"
                                                name="message"
                                                rows={10}
                                                placeholder="Enter message here ..."
                                                ref={register({
                                                    required: "Please enter your message"
                                                })}
                                            ></textarea>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {/*<PillButton*/}
                                            {/*    text={'Send'}*/}
                                            {/*    link={'#'}*/}
                                            {/*    onClick={false}*/}
                                            {/*    icon={faEnvelope}*/}
                                            {/*/>*/}

                                            <Button
                                                className={'btn-xl pill-button'}
                                                variant={'dark'}
                                                type={'submit'}
                                            >
                                                <span className={'mr-2'}>
                                                    <FontAwesomeIcon size={'sm'} icon={faEnvelope} style={{marginRight: '10px'}}/>
                                                </span>
                                                Send
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <small>Atomic Lotus, LLC is committed to protecting and respecting your privacy, and we will never share or sell your information. From time to time -  typically only 3-4 times per year - we would like to contact you about our products and special offers. If you are okay with that, please tick the box below.</small>
                                        </Col>
                                    </Row>
                                </Container>
                            </form>

                        </Col>
                        <Col md={1} xs={false} sm={false}/>
                    </Row>
                </Container>
            </ArticlePage>
        </React.Fragment>
    )
})

export default ContactPage;
